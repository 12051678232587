import React from 'react';



const BlockOne = () => {


    return (
        <div className=' py-[100px]'>
            <div className='w-full md:w-[80%] mx-auto text-center'>
                <h1 className=' font-bold text-white'>Expert Team</h1>
                <div className=' flex flex-col-reverse md:flex-row justify-center mt-14 items-center px-5 md:px-10 space-x-0 md:space-x-4 '>

                    <div className='w-full md:w-1/2 h-full flex flex-col md:flex-row items-start md:items-end py-10'>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/M%20%26%20H%20Remodeling%20LLC%2FM%20%26%20H%20Remodeling%20LLC.jpg?alt=media&token=61488a37-decf-46f6-9503-2771ad47503e"
                            alt='no found'
                            loading='lazy'
                            className='w-[350px] md:w-[350px] h-[350px] md:h-[500px] object-fill border-4 border-white rounded-2xl'
                        />
                        <div className=' w-full md:w-[350px] h-auto rounded-2xl ml-0 md:-ml-20 mb-0 md:-mb-12 bg-white text-black p-8 shadow-xl shadow-secondary'>
                            <h5 className='text-primary font-bold'>Miguel Morales</h5>
                            <p>What i like most about my job is the satisfaction of knowing that our clients have been completely satisfied with our work, the pride of having another project already finished.</p>
                        </div>

                    </div>

                    <div className='w-full md:w-1/2 h-full flex flex-col md:flex-row items-start md:items-end'>

                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/M%20%26%20H%20Remodeling%20LLC%2FM%20%26%20H%20Remodeling%20LLC-2.jpg?alt=media&token=5165f453-67ac-43d6-9d7c-789c44cc9e81"
                            alt='no found'
                            loading='lazy'
                            className='w-[350px] md:w-[350px] h-[350px] md:h-[500px] object-fill border-4 border-white rounded-2xl'
                        />
                        <div className='w-auto md:w-[350px] h-auto rounded-2xl ml-0 md:-ml-32 mb-0 md:-mb-20 bg-white text-black p-8 shadow-xl shadow-secondary'>
                            <h5 className='text-primary font-bold'>Hector Alvizures</h5>
                            <p>My greatest satisfaction is seeing the happy face of our clients when they see each of their projects finished. Our goal is to make your dream come true, making your vision come true, is what we do.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}


export default BlockOne;